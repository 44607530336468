import gql from 'graphql-tag';

export const bannerQuery = gql`
    query GetBanners($placement: String!, $slotIds: [String!]!, $searchTerm: String, $storeId: Int, $customerUuid: String, $cartId: Int, $sessionId: String) {
        bannerImages(placement: $placement, slotIds: $slotIds, searchTerm: $searchTerm, storeId: $storeId, customerUuid: $customerUuid, cartId: $cartId, sessionId: $sessionId) {
          altText
          bannerImageId
          bidRanking
          imageUrl
          link
          productIds
          slotId
        }
    }
`;
