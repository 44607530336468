import React, {useEffect, useState} from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import {makeStyles} from '@material-ui/styles';
import {colors} from '@hy-vee/themes';

const useStyles = ({
    color,
    boxShadow
}: any) => makeStyles({
    bar: {
        backgroundColor: color

    },
    root: {
        background: colors.grey[300],
        boxShadow: boxShadow ? '0px 4px 4px 0px #00000040' : ''
    }
})();

const ProgressBar = ({
    value,
    color,
    boxShadow = true,
    ...props
}: any) => {
    const [progress, setProgress] = useState(0);

    const classes = useStyles({
        boxShadow,
        color
    });

    useEffect(() => {
        if (value > progress) {
            setInterval(() => {
                setProgress((oldProgress) => Math.min(oldProgress + 5, value));
            }, 250);
        }
    }, [progress, value]);

    return (
        <LinearProgress
            {...props}
            classes={{
                bar: classes.bar,
                root: classes.root
            }}
            value={progress}
            variant="determinate"
        />
    );
};

export default ProgressBar;
