import React, {FC} from 'react';
import styled, {css} from 'styled-components';
import {colors} from '@hy-vee/themes';

import useWindowSize from '../../hooks/use-window-size';
import ProgressBar from '../progress-bar';
import {getDonationsProgress} from '../../helpers/donations-helper';
import {ICmsMarketingDealsDonationBanner} from '../../autogen/ICmsMarketingDealsDonationBanner';

const getStyleProps = (bgColor?: string, secondaryBgColor?: string) => {
    if (!bgColor) {
        return 'background-color: transparent;';
    }

    if (!secondaryBgColor) {
        return `background-color: ${bgColor};`;
    }

    return `background: linear-gradient(to right, ${bgColor} 50%, ${secondaryBgColor} 50%);`;
};

const BrandImage = styled.img`
    max-width: 100%;
    height: auto;
    max-height: 400px;
`;

const Container = styled.a<{bgColor?: string, secondaryBgColor?: string}>`
    position: relative;
    display: flex;
    overflow: hidden;
    text-decoration-color: transparent;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1px;
    line-height: 0px;

    ${(props) => getStyleProps(props.bgColor, props.secondaryBgColor)}

    @media (min-width: 768px) {
        flex-direction: row;
    }
`;

const ProgressBarCon = styled.div<{isTablet: boolean, isDesktopWidth: boolean}>`
    position: absolute;
    right: 24px;
    top: 46px;
    width: 50%;
    height: 34px;
    text-align: center;
    
    ${(props) =>
        props.isTablet &&
        css`
            top: 18%;
            right: 62px;

        `};

    ${(props) =>
        props.isDesktopWidth &&
        css`
            width: 21%;
            top: 75%;
            left: 1.25%;
            right: unset;
        `};
`;

const MealsText = styled.span<{isDesktopWidth: boolean}>`
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0em;
    color:  ${(props) => props.isDesktopWidth ? 'white' : colors.grey[500]};
    padding-bottom: 16px;
`;

const StyledProgressBar = styled(ProgressBar)`
    margin-bottom: 10px;
    border-radius: 2px;

    div {
        border-radius: 2px;
    }
`;

const ContentContainer = styled.div`
    position: relative;
    max-height: 400px;
`;

const TextContainer = styled.div`
    padding-bottom: 16px;
`;

const DonationBanner: FC<ICmsMarketingDealsDonationBanner> = ({
    progressBar,
    href,
    backgroundColor,
    secondaryBackgroundColor,
    desktopImage,
    tabletImage,
    mobileImage
}) => {
    const {isDesktopWidth, isTabletWidth} = useWindowSize();

    const donationProgress = progressBar && getDonationsProgress(progressBar.currentDollar, progressBar.hyveeDollarDonation, progressBar.goalDollar);
    let image = mobileImage;

    if (isDesktopWidth) {
        image = desktopImage;
    } else if (isTabletWidth) {
        image = tabletImage;
    }

    const progressBarColor = isDesktopWidth ? 'rgba(78, 91, 49, 0.42)' : '#4E5B31';

    if (!image) {
        return null;
    }

    return (
        <Container
            bgColor={backgroundColor?.hex}
            data-testid="donations-banner-container"
            href={href}
            secondaryBgColor={secondaryBackgroundColor?.hex}
        >
            <ContentContainer>
                <BrandImage
                    alt={image.alt}
                    src={image.asset.url}
                />
                {Boolean(donationProgress) && (
                    <ProgressBarCon
                        isDesktopWidth={isDesktopWidth}
                        isTablet={isTabletWidth}
                    >
                        <TextContainer>
                            <MealsText isDesktopWidth={isDesktopWidth}>
                                {`${donationProgress.meals.toLocaleString()} meals donated`}
                            </MealsText>
                        </TextContainer>
                        <StyledProgressBar
                            boxShadow={false}
                            color={progressBarColor}
                            value={donationProgress.percentage}
                        />
                    </ProgressBarCon>
                )}
            </ContentContainer>
        </Container>
    );
};

export default DonationBanner;
