import {useEffect, useState, useCallback} from 'react';

import {PERFORMANCE, TARGETING, FUNCTIONAL} from '../enums/one-trust-cookie-categories';

declare global {
    // Not prefixing this with "I" allows TS to merge this interface def
    // with the existing Window interface def

    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface Window {
        OptanonActiveGroups?: string
    }
}

type UseCitrusSessionIdProps = {
    withConsentCheck: boolean;
};

const DEFAULT_ARGS = {withConsentCheck: true};

export const SESSION_COOKIE_NAME = 'dtm_token';
export const CONSENT_UPDATE_EVENT = 'OneTrustGroupsUpdated';

export const useCitrusSessionId = (props: UseCitrusSessionIdProps = DEFAULT_ARGS): string | null => {
    const [sessionId, setSessionId] = useState<string | null>(null);

    const {withConsentCheck} = props;

    const parseCookie = useCallback(() => {
        const cookieIdent = `${SESSION_COOKIE_NAME}=`;
        const cookie = document.cookie.split(';').find((value) => value.includes(cookieIdent));

        if (cookie) {
            const citrusSessionId = cookie.replace(cookieIdent, '').trim();

            setSessionId(citrusSessionId);
        }
    }, []);

    useEffect(() => {
        const {OptanonActiveGroups} = window;

        const userConsentsToPerformanceCookies = withConsentCheck
            ? !OptanonActiveGroups || OptanonActiveGroups.includes(PERFORMANCE)
            : true;

        if (userConsentsToPerformanceCookies) {
            parseCookie();
        }
    }, [withConsentCheck, parseCookie]);

    useEffect(() => {
        const handleConsentUpdate = (
            event: CustomEvent<(typeof FUNCTIONAL | typeof PERFORMANCE | typeof TARGETING)[]>
        ): void => {
            if (!event.detail.includes(PERFORMANCE)) {
                setSessionId(null);
            } else {
                parseCookie();
            }
        };

        window.addEventListener(CONSENT_UPDATE_EVENT, handleConsentUpdate);

        return () => window.removeEventListener(CONSENT_UPDATE_EVENT, handleConsentUpdate);
    }, [parseCookie]);

    return sessionId;
};
