import gql from 'graphql-tag';

import {productFragment, storeProductFragment} from './deals-queries';

export const getStoreProductsByProductId = gql`
    ${storeProductFragment}
    ${productFragment}

    query GetStoreProductsByProductId(
        $productId: Int!, 
        $storeId: Int!, 
        $locationId: ID = "",
        $targeted: Boolean = false,
        $perksPricingEnabled: Boolean = false
    ){
        storeProducts(where: {productId: $productId, storeId: $storeId, isActive: true}) {
            storeProducts {
                ...IStoreProduct
                product {
                    ...IProduct
                }
            }
        }
    }
`;
