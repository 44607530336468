import gql from 'graphql-tag';

export const getCashBackOffers = gql`
    query getCashBackOffers {
        cashBackOffers {
            cashBackOffers {
                cashBackOfferCode
                cashBackOfferId
                description
                imageUrl
                offerEndDate
                offerState
                valueText
            }
        }
    }
`;

export const getActivatedAndExpiredCashBackOffers = gql`
    query getActivatedAndExpiredCashBackOffers {
        cashBackOffers(offerStates: ["activated", "expired"]) {
            cashBackOffers {
                cashBackOfferCode
                cashBackOfferId
                description
                imageUrl
                offerEndDate
                offerState
                valueText
            }
        }
    }
`;

export const getReceiptImageUploadsUrls = gql`
    query ReceiptImageUploadUrls($numberOfImages: Int!, $imageTypes: [String]!) {
      receiptImageUploadUrls(numberOfImages: $numberOfImages, imageTypes: $imageTypes) {
        referenceUrl
        uploadUrl
      }
    }
`;

export const getCashBackOffer = gql`
    query getCashBackOffer ($cashBackOfferId: ID!) {
        cashBackOffer(cashBackOfferId: $cashBackOfferId) {
            cashBackOfferCode
            cashBackOfferId
            category
            imageUrl
            offerStartDate
            offerEndDate
            brand
            value
            valueText
            description
            terms
            activated
            upcs
            offerState
        }
    }
`;

export const activateCashBackOfferQuery = gql`
    mutation activateCashBackOfferQuery ($cashBackOfferId: ID!) {
        activateCashBackOffer(cashBackOfferId: $cashBackOfferId) {
            cashBackOfferId
            activated
        }
    }
`;

export const getCashBackOfferByUpc = gql`
    query getCashBackOfferByUpc($upc: String!) {
        cashBackOfferByUpc(upc: $upc) {
            brand
            cashBackOfferCode
            cashBackOfferId
            description
            imageUrl
            value
            valueText
            upcs
        }
    }
`;
