import React from 'react';

import SerializerContext from './context';

const useSerializer = () => {
    const serializerContext = React.useContext(SerializerContext);

    if (serializerContext === null) {
        throw new Error('Serializer must be inside the Serializer Provider');
    }

    return serializerContext;
};

export {useSerializer};
