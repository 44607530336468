import gql from 'graphql-tag';

import {itemFragment} from './item-fragments';

export const productFragment = gql`
    fragment IProduct on product {
        productId
        productImages {
            isPrimary
            uri
            type
            size
            viewType
        }
        name
        size
        averageWeight
        couponProductV4(targeted: $targeted) {
            upc
            couponsV4 {
                couponId
                brand
                offerState
                valueText
            }
        }
    }
`;

export const storeProductFragment = gql`
    fragment IStoreProduct on storeProduct {
        storeProductId
        productId
        storeId
        onFuelSaver
        onSale
        fuelSaver
        price
        priceMultiple
        isWeighted
        storeProductDescriptions {
            type
            description
        }
    }
`;

export const dealFragment = gql`
    fragment IDeal on deal {
        dealId
        adId
        dealTitle
        dealDescription
        fuelSaverDescription
        size
        varietiesCount
        imageUri
        adPageNumber
    }
`;

export const storeDealFragment = gql`
    ${dealFragment}

    fragment IStoreDeal on storeDeal {
        dealId
        price
        priceMultiple
        storeId
        beginDate
        endDate
        deal {
            ...IDeal
        }
    }
`;

export const getDealSeo = gql`
        
    query GetDealSeo($dealId: ID!, $pageSize: Int) {
        getStoreDeals(dealId: $dealId, pageSize: $pageSize) {
            storeDeals {
                deal {
                    dealTitle
                    imageUri
                }
            }
        }
    }
`;

export const getDealDetails = gql`
    
    query GetDealDetails($dealId: ID!, $storeId: Int) {
        getStoreDeals(dealId: $dealId, storeId: $storeId) {
            storeDeals {
                deal {
                    dealTitle
                    dealDescription
                    imageUri
                    size
                    varietiesCount
                }
                beginDate
                endDate
                price
                priceMultiple
            }
        }
    }
`;

export const getPaginatedDealsProducts = gql`
    ${storeProductFragment}
    ${productFragment}
    ${itemFragment}
    
    query GetPaginatedDealsProducts(
        $dealId: ID!
        $storeId: Int!
        $page: Int!
        $pageSize: Int!
        $targeted: Boolean = false
        $locationId: ID = ""
        $perksPricingEnabled: Boolean = false
    ){
        getStoreProductsByDealId(dealId: $dealId, storeId: $storeId, page: $page, pageSize: $pageSize) {
            storeProducts {
                ...IStoreProduct
                product {
                    ...IProduct
                    item {
                        ...IItemFragment
                    }
                }
            }
            nextPage
        }
    }
`;

export const dealGroupsQuery = gql`
    ${storeDealFragment}

    query DealGroupsQuery ($storeId: Int!, $storeAdId: ID, $adPageNumber: Int, $page: Int = 1, $pageSize: Int, $where: storeDealsInput) {
        pageableStoreDeals: getStoreDeals (storeId: $storeId, storeAdId: $storeAdId, adPageNumber: $adPageNumber, page: $page, pageSize: $pageSize, where: $where) {
            page
            nextPage
            lastPage
            storeDeals {
                ...IStoreDeal
            }
        }
    }
`;

