import {useMutation} from '@apollo/client';
import type {ICartActions} from '@hy-vee/react-web-and-mobile-ui-components';

import {useCustomerContext} from '../contexts/customer-context';
import {createCartItemMutation} from '../../src/graphql/mutations/cart-mutations';
import {getCartItemFromCart} from '../helpers/cart-helpers';
import {onChangeAmountInCart} from '../helpers/product-card-helpers';

import {useStoreProductByProductId} from './use-store-product';
import {useAislesOnlineCart} from './use-cart';

const useAislesOnlineCartItemCallback = (productId: string | number): ICartActions => {
    const [createCartItemCallback] = useMutation(
        createCartItemMutation
    );
    const {customer} = useCustomerContext();
    const {data: aislesOnlineCart} = useAislesOnlineCart();
    const cartItem = getCartItemFromCart(aislesOnlineCart, productId);
    const {data: storeProduct} = useStoreProductByProductId(productId);

    return {
        getQuantityInCartCallback: () => cartItem?.quantity ?? 0,
        onChangeAmountInCart: storeProduct && onChangeAmountInCart(
            createCartItemCallback,
            cartItem,
            storeProduct.product,
            storeProduct,
            customer
        )
    };
};

// eslint-disable-next-line react-hooks/rules-of-hooks
export const createAislesOnlineCartItemCallback = (productId: string | number) => useAislesOnlineCartItemCallback(productId);
