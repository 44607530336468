import {useQuery} from '@apollo/client';

import ID from '../types/Id';
import IApolloQuery from '../types/apollo-query';
import {getStoreProductsByProductId} from '../../src/graphql/queries/store-product-queries';
import {
    GetStoreProductsByProductId,
    GetStoreProductsByProductId_storeProducts_storeProducts,
    GetStoreProductsByProductIdVariables
} from '../autogen/GetStoreProductsByProductId';

import {useStore} from './use-store';

interface IUseStoreProduct {
    data: GetStoreProductsByProductId_storeProducts_storeProducts | undefined;
    loading: boolean;
}

export const useStoreProductByProductId = (productId: ID | null): IUseStoreProduct => {
    const {data: storeData} = useStore();

    const {data, loading} = useQuery<GetStoreProductsByProductId, IApolloQuery<GetStoreProductsByProductIdVariables>>(
        getStoreProductsByProductId,
        {
            errorPolicy: 'all',
            fetchPolicy: 'cache-first',
            skip: !productId || !storeData?.storeId,
            ssr: false,
            variables: {
                productId: Number(productId),
                storeId: Number(storeData?.storeId)
            }
        }
    );

    return {
        data: data?.storeProducts?.storeProducts?.[0],
        loading
    };
};
