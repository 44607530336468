import gql from 'graphql-tag';

export const retailItemsFragment = gql`
    fragment IRetailItemFragment on RetailItem {
        basePrice
        basePriceQuantity
        soldByUnitOfMeasure {
            code
            name
        }
        ecommerceTagPrice
        ecommerceTagPriceQuantity
        memberTagPrice @include(if: $perksPricingEnabled)
    }
`;

export const itemFragment = gql`
    ${retailItemsFragment}
    fragment IItemFragment on Item {
        itemId
        description
        ecommerceStatus
        unitAverageWeight
        retailItems(locationIds: [$locationId]) {
            ...IRetailItemFragment
        }
    }
`;
