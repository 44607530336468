import {
    AddToListTypes,
    CartItemTypes,
    ItemTypes
} from '@hy-vee/react-web-and-mobile-ui-components';
// eslint-disable-next-line no-duplicate-imports
import type {
    IBodyContent,
    ICartActions,
    IContextProps
} from '@hy-vee/react-web-and-mobile-ui-components';
import getConfig from 'next/config';
import {useRouter} from 'next/router';
import React, {useEffect, useMemo, useState} from 'react';

import {useModalContext} from '../../components/modals/modal-provider';
import {reportCitrusAnalytics} from '../../helpers/citrus-banners-helpers';
import {onChangeAmountInCartWhammy} from '../../helpers/product-card-helpers';
import {createAislesOnlineCartItemCallback} from '../../hooks/use-aisles-online-cart-actions';
import {useAislesOnlineCart} from '../../hooks/use-cart';
import {useActivateCashBackOfferMutator} from '../../hooks/use-cash-back-offers';
import {useCitrusBannersClickMutator, useCitrusBannersImpressionMutator} from '../../hooks/use-citrus-banners';
import {useStore} from '../../hooks/use-store';
import {error as clientLoggerError, flush as clientLoggerFlush} from '../../services/logging-service';
import {getComponentSerializer} from '../../services/serializer-service';
import ID from '../../types/Id';
import {clipCoupon, goToHyVeeUrl, seeAdDealDetails, seeDetails} from '../../utils/callback-helpers';
import {login} from '../../utils/navigation-helpers';
import {useCustomerContext} from '../customer-context';

import SerializerContext from './context';
import {ISerializerProvider} from './types';

const clientSidelogErrorBoundary = (router, error, info) => {
    clientLoggerError(router?.pathname || 'unknown', error, 'Component Error Boundary', info);
};

const createCartActions = (type: CartItemTypes, id: number | string): ICartActions | undefined => {
    if (type === CartItemTypes.PRODUCT) {
        return createAislesOnlineCartItemCallback(id);
    }

    return undefined;
};

const SerializerProvider: React.FC<ISerializerProvider> = ({children, hasWebkitSupport}) => {
    const router = useRouter();
    const {addToList, coupon, storeSelector} = useModalContext();
    const {data: storeData} = useStore();
    const {data: cart} = useAislesOnlineCart();
    const {
        isAuthenticated,
        hasFuelSaver,
        hasHPlusMembership,
        loading: customerLoading,
        isNationalCustomer,
        customer
    } = useCustomerContext();
    const {hyveeBaseUrl} = getConfig().publicRuntimeConfig;
    const [serializer, setSerializer] = useState<IBodyContent['serializers']>();
    const loading = useMemo(() => Boolean(customerLoading), [
        customerLoading
    ]);

    const {activateCashBackOfferMutator} = useActivateCashBackOfferMutator();
    const {citrusBannerClick} = useCitrusBannersClickMutator();
    const {citrusBannerImpression} = useCitrusBannersImpressionMutator();
    const userFlags = useMemo(() => ({
        customerId: customer?.customerId,
        customerType: isNationalCustomer ? 'national' : 'local',
        customerUuid: customer?.customerUuid,
        environment: process.env.NODE_ENV,
        fuelSaverCardNumber: customer?.fuelSaverNumber,
        hasFuelSaver,
        hasHPlusMembership,
        hasStore: storeData,
        hasWebkitSupport,
        isAuthenticated,
        isNationalCustomer,
        showForNationalCustomers: isNationalCustomer
    }), [customer, isNationalCustomer, hasFuelSaver, hasHPlusMembership, storeData, hasWebkitSupport, isAuthenticated]);

    const callbacks = useMemo<IContextProps['callbacks']>(() => ({
        activateCallback: async (cashBackOfferId: string, setState?: (string?) => void) => {
            await activateCashBackOfferMutator(cashBackOfferId, setState);
        },
        addToListCallback: (type: AddToListTypes, id: ID) => addToList.addToList(type, id),
        cartActions: createCartActions,
        clipCallback: clipCoupon,
        errorBoundaryCallback: (error, info) => clientSidelogErrorBoundary(router, error, info),
        getHyVeeBaseUrl: () => hyveeBaseUrl,
        goToHyVeeUrlCallback: goToHyVeeUrl,
        handleProductTabsChange: null,
        loginCallback: () => login(router.asPath),
        onChangeAmountInCart: onChangeAmountInCartWhammy,
        registerCallback: () => router.push(`/my-account/sign-up?redirect=${router.asPath}`),
        reportSlotBannerAnalytics: (bannerImageId, type) => {
            reportCitrusAnalytics(
                cart?.cartId,
                citrusBannerClick,
                citrusBannerImpression,
                type,
                bannerImageId
            );
        },
        seeAdDealDetailsCallback: (dealId: any) => seeAdDealDetails(dealId),
        seeDetailsCallback: (itemId: any, itemType: ItemTypes) => seeDetails(itemId, itemType),
        showCouponModal: (id) => coupon.show(id),
        storeSelectorVisibleCallback: () => storeSelector.show()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [addToList, cart, hyveeBaseUrl, router, storeSelector]);

    useMemo(() => setSerializer(getComponentSerializer(callbacks, userFlags)), [callbacks, userFlags]);
    useEffect(() => () => {
        clientLoggerFlush();
    }, []);

    const providerValue = useMemo(() => ({
        callbacks,
        loading,
        serializer,
        userFlags
    }), [callbacks, loading, serializer, userFlags]);

    return (
        <SerializerContext.Provider
            value={providerValue}
        >
            {children}
        </SerializerContext.Provider>
    );
};

export default SerializerProvider;
