import {useQuery, useMutation} from '@apollo/client';

import {
    activateCashBackOfferQuery,
    getCashBackOffer,
    getCashBackOffers,
    getActivatedAndExpiredCashBackOffers
} from '../../src/graphql/queries/cash-back-offer-queries';
import {TWO_LEGGED_AUTH_TYPE, THREE_LEGGED_AUTH_TYPE} from '../enums/auth-types';
import {getCashBackFaqPage} from '../../src/graphql/queries/sanity-queries';
import {useCustomerContext} from '../contexts/customer-context';

export const useCashBackOffers = () => {
    const {isAuthenticated, loading: customerLoading} = useCustomerContext();
    const {data, error, loading, refetch} = useQuery(getCashBackOffers, {
        skip: customerLoading,
        ssr: false,
        variables: {
            authType: isAuthenticated ? THREE_LEGGED_AUTH_TYPE : TWO_LEGGED_AUTH_TYPE
        }
    });

    return {
        data: data?.cashBackOffers?.cashBackOffers || [],
        error,
        loading: customerLoading || loading,
        refetch
    };
};

export const useCashBackOffer = (cashBackOfferId: string) => {
    const {isAuthenticated, loading: customerLoading} = useCustomerContext();
    const response = useQuery(getCashBackOffer, {
        skip: customerLoading,
        ssr: false,
        variables: {
            authType: isAuthenticated ? THREE_LEGGED_AUTH_TYPE : TWO_LEGGED_AUTH_TYPE,
            cashBackOfferId
        }
    });

    const {data, loading} = response;

    return {
        data: data?.cashBackOffer || null,
        loading: customerLoading || loading
    };
};

export const useActivatedAndExpiredCashBackOffers = () => {
    const {isAuthenticated, loading: customerLoading} = useCustomerContext();
    const {data, error, loading, refetch} = useQuery(getActivatedAndExpiredCashBackOffers, {
        skip: Boolean(customerLoading || !isAuthenticated),
        ssr: false,
        variables: {
            authType: THREE_LEGGED_AUTH_TYPE
        }
    });

    return {
        data: data?.cashBackOffers?.cashBackOffers || [],
        error,
        loading: customerLoading || loading,
        refetch
    };
};

export const useActivateCashBackOfferMutator = () => {
    const standardVariables = {
        authType: THREE_LEGGED_AUTH_TYPE
    };

    const [mutator, {loading, error}] = useMutation(activateCashBackOfferQuery, {
        variables: {
            ...standardVariables,
            cashBackOfferId: null
        }
    });

    return {
        activateCashBackOfferMutator: async (cashBackOfferId: string, setState?: (string?) => void) => {
            let activated = false;

            try {
                if (setState) {
                    setState('loading');
                }

                const mutationResult = await mutator({
                    variables: {
                        ...standardVariables,
                        cashBackOfferId
                    }
                });

                activated = mutationResult.data.activateCashBackOffer.activated;
            } catch {}

            if (setState) {
                setState(activated ? 'activated' : null);
            }

            return activated;
        },
        error,
        loading
    };
};

export const useCashBackOffersFaqPage = () => {
    const {data, error, loading} = useQuery(getCashBackFaqPage, {
        ssr: false,
        variables: {
            authType: TWO_LEGGED_AUTH_TYPE
        }
    });

    return {
        data: data?.allCmsCashBackOffersFAQPage?.body || [],
        error,
        loading
    };
};
