import {gql} from '@apollo/client';

export const citrusBannersClickMutator = gql`
    mutation CitrusBannersClickMutator($cartId: Int, $vendorAdId: String!) {
        createEcommerceClickEvent(cartId: $cartId, vendorAdId: $vendorAdId) {
            messageId
        }
    }
`;

export const citrusBannersImpressionMutator = gql`
    mutation CitrusBannersImpressionMutator($cartId: Int, $vendorAdId: String!) {
        createEcommerceImpressionEvent(cartId: $cartId, vendorAdId: $vendorAdId) {
            messageId
        }
    }
`;
