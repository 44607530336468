export const getDonationsProgress = (currentDollar, hyveeDollarDonation, goalDollar) => {
    const currentDonations = currentDollar + hyveeDollarDonation;
    const percentage = Math.min(currentDonations / goalDollar * 100, 100);
    const meals = Math.floor(currentDonations * 10);

    return {
        meals,
        percentage
    };
};
