// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/display-name */
import React from 'react';
import {WebComponents, getBodyContentSerializer} from '@hy-vee/react-web-and-mobile-ui-components';

import DonationBanner from '../components/donations/donation-banner';

export const getComponentSerializer = (callbacks: any, userFlags: any) => {
    const contextProps = {
        callbacks,
        userFlags
    };
    const baseSerializer = getBodyContentSerializer(WebComponents, contextProps);

    return {
        ...baseSerializer,
        types: {
            ...baseSerializer.types,
            cmsMarketingDealsDonationBanner: (props: any) =>
                <DonationBanner
                    key="cmsMarketingDealsDonationBanner"
                    {...props}
                />
        }
    };
};
