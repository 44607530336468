type ICitrusAnalyticType = 'CLICK' | 'IMPRESSION';

export const reportCitrusAnalytics = (
    cartId: string,
    clickFn: (cartIdValue: string, vendorAdIdValue: string) => void,
    impressionFn: (cartIdValue: string, vendorAdIdValue: string) => void,
    type: ICitrusAnalyticType,
    vendorAdId: string
) => {
    if (type === 'CLICK') {
        clickFn(cartId, vendorAdId);
    } else if (type === 'IMPRESSION') {
        impressionFn(cartId, vendorAdId);
    }
};
