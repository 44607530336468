import {useMutation, useQuery} from '@apollo/client';
import {getBannerSlotIdsFromSanityData} from '@hy-vee/react-web-and-mobile-ui-components';
import {useMemo} from 'react';

import {useCustomerContext} from '../contexts/customer-context';
import {citrusBannersClickMutator, citrusBannersImpressionMutator} from '../../src/graphql/mutations/citrus-banners-mutations';
import {bannerQuery} from '../../src/graphql/queries/citrus-banner-queries';
import {GetBanners, GetBanners_bannerImages, GetBannersVariables} from '../autogen/GetBanners';
import {TWO_LEGGED_AUTH_TYPE} from '../enums/auth-types';
import {WithTwoLeggedAuthType} from '../types/utils';
import {parseCartId} from '../helpers/cart-helpers';

import {useStore} from './use-store';
import {useAislesOnlineCart} from './use-cart';
import {useCitrusSessionId} from './use-citrus-session-id';

interface ICitrusBannersFromSanityData {
    data: GetBanners_bannerImages[],
    loading: boolean
}

type IBannerVariables = WithTwoLeggedAuthType<GetBannersVariables>;

export const useSlotBannersFromSanityData = (sanityData: any[] | undefined, placement = 'Home'): ICitrusBannersFromSanityData => {
    const slotIds = useMemo(() => sanityData && sanityData.length ?
        getBannerSlotIdsFromSanityData(sanityData) : [],
    [sanityData]);
    const hasSlotIds = Boolean(slotIds.length);
    const {isAuthenticated, loading: customerLoading, customer} = useCustomerContext();
    const {data: cartData, loading: cartLoading} = useAislesOnlineCart();
    const sessionId = useCitrusSessionId();

    const {data: storeData, loading: storeLoading} = useStore();

    const variables: IBannerVariables = {
        authType: TWO_LEGGED_AUTH_TYPE,
        cartId: parseCartId(cartData),
        customerUuid: customer?.customerUuid,
        placement,
        sessionId,
        slotIds
    };

    if (isAuthenticated && storeData?.storeId) {
        variables.storeId = Number.parseInt(storeData.storeId);
    }

    const {data, loading} = useQuery<GetBanners>(bannerQuery, {
        fetchPolicy: 'no-cache',
        skip: !hasSlotIds || storeLoading || customerLoading || cartLoading,
        ssr: false,
        variables
    });

    return {
        data: hasSlotIds ? data?.bannerImages : [],
        loading
    };
};

const mutation = async (mutator, mutatorVariables) => {
    let data;

    try {
        data = (await mutator({...mutatorVariables})).data;
    } catch {}

    return data;
};

export const useCitrusBannersClickMutator = () => {
    const defaultVariables = {
        authType: TWO_LEGGED_AUTH_TYPE
    };

    const [mutator, {loading, error}] =
        useMutation(
            citrusBannersClickMutator,
            {variables: {...defaultVariables}}
        );

    return {
        citrusBannerClick: (
            cartId: string,
            vendorAdId: string
        ) => {
            const mutatorVariables = {
                context: {
                    fetchOptions: {
                        keepalive: true
                    }
                },
                variables: {
                    ...defaultVariables,
                    cartId: Number.parseInt(cartId) || null,
                    vendorAdId
                }
            };

            mutation(mutator, mutatorVariables);
        },
        error,
        loading
    };
};

export const useCitrusBannersImpressionMutator = () => {
    const defaultVariables = {
        authType: TWO_LEGGED_AUTH_TYPE
    };

    const [mutator, {loading, error}] =
        useMutation(
            citrusBannersImpressionMutator,
            {variables: {...defaultVariables}}
        );

    return {
        citrusBannerImpression: (
            cartId: string,
            vendorAdId: string
        ) => {
            const mutatorVariables = {
                variables: {
                    ...defaultVariables,
                    cartId: Number.parseInt(cartId) || null,
                    vendorAdId
                }
            };

            mutation(mutator, mutatorVariables);
        },
        error,
        loading
    };
};
